import '../App.css';

function About() {
    return (
    <div className="App">
      
        <p>
          This is the about page !
        </p>
    </div>
    );
}

export default About;