import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import jwt from "jsonwebtoken";
import './login.css';

interface LoginState {
  email: string;
  password: string;
}

const initialState: LoginState = {
  email: '',
  password: '',
};


function Login() {
  const [state, setState] = useState<LoginState>(initialState);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies(['loginStatus', 'email', 'token', 'userId']);


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Handle login logic here
    axios.post("https://api.ritadhi.com/login", {
      UserName: state.email,
      Password: state.password,
    })
      .then((res) => {
        if (res.status === 200) {
          setError("");
          const decoded = jwt.decode(res.data.token);
          const tokenObject = decoded as { username: string, userid: string, exp: number };
          const tokenExpiry = new Date(tokenObject.exp * 1000);
          // set cookies 
          setCookie("loginStatus", "1", { path: "/", expires: tokenExpiry });
          setCookie("email", tokenObject.username, { path: "/", expires: tokenExpiry });
          setCookie("token", res.data.token, { path: "/", expires: tokenExpiry });

          if (decoded) {
            setCookie("userId", tokenObject.userid, { path: "/", expires: tokenExpiry });
          }
          // Redirect to the home page.
          navigate('/');
        } else {

          setError(res.data.message);
        }
      })
      .catch((err) => {
        setError(err.message);
      });

  };

  return (
    <div className="App">
      <header className="App-header"></header>
      <div className="login-body">
        <div className="login-container">
          <h1>Login</h1>
          <form id="login-form" onSubmit={handleSubmit}>
            <div>{error && <p>{error}</p>}</div>
            <div className="form-group">
              <label htmlFor="email">Username:</label>
              <input id="username" type="email" name="email" value={state.email} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input type="password" name="password" value={state.password} onChange={handleInputChange} />
            </div>
            <button type="submit">Log In</button>
            <Link className="NavBar-Link" to="/signup">Sign Up</Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;