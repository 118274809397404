import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";

interface SignupState {
    email: string;
    password: string;
  }
  
  const initialState: SignupState = {
    email: '',
    password: '',
  };
  

function Signup() {
    const [state, setState] = useState<SignupState>(initialState);
    const [error, setError] = useState("");
    const navigate = useNavigate();
  
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setState({ ...state, [name]: value });
    };
  
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      // Handle login logic here
      axios.post("https://api.ritadhi.com/signup", {
        UserName : state.email,
        Password : state.password,
        Email : state.email,
        FirstName : "",
        LastName : "",
        UserId : 0
      })
        .then((res) => {
          if (res.status === 201) {
            setError("");
            // Redirect to the home page.
            navigate('/');
          } else {
            setError(res.data.message);
          }
        })
        .catch((err) => {
          setError(err.message);
        });
      
    };
  
    return (
        
      <form onSubmit={handleSubmit}>
        <div>{error && <p>{error}</p>}</div>
        <div>
          <label htmlFor="email">Email</label>
          <input type="email" name="email" value={state.email} onChange={handleInputChange} />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input type="password" name="password" value={state.password} onChange={handleInputChange} />
        </div>
        <button type="submit">Sign Up</button>
      </form>
    );
  }

    export default Signup;