
import { useCookies } from 'react-cookie';
import '../App.css';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

interface Message {
  ConversationId: string;
  Message: string;
}

function Home() {
  const [cookies] = useCookies(['loginStatus', 'email', 'token', 'userId']);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchMessages = useCallback(async () => {
    console.log(cookies.token);
    if (cookies.userId === undefined) {
      console.log("User unauthorized");
      return;
    }
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + cookies.token;
    axios.get("https://api.ritadhi.com/GetByUserId/" + cookies.userId).then((res) => {
      if (res.status === 200) {
        setMessages(res.data as Message[]);
      } else {
        setMessages([]);
      }
    })
      .catch((err) => {
        setMessages([
          {
            ConversationId: "1",
            Message: "Test Message 1 which should be long enough to wrap around the screen and test the overflow. Once it's tested then we can move on to the next test."
          },
          {
            ConversationId: "1",
            Message: "Test Message 2. we should be able to see this message as well. And this should be long enough to wrap around the screen and test the overflow. Once it's tested then we can move on to the next test."
          },
          {
            ConversationId: "1",
            Message: "Test Message 3. we should be able to see this message as well. And this should be long enough to wrap around the screen and test the overflow. Once it's tested then we can move on to the next test."
          },
          {
            ConversationId: "1",
            Message: "Test Message 4. we should be able to see this message as well. And this should be long enough to wrap around the screen and test the overflow. Once it's tested then we can move on to the next test."
          },
          {
            ConversationId: "1",
            Message: "Test Message 5. we should be able to see this message as well. And this should be long enough to wrap around the screen and test the overflow. Once it's tested then we can move on to the next test."
          },
          {
            ConversationId: "1",
            Message: "Test Message 6. we should be able to see this message as well. And this should be long enough to wrap around the screen and test the overflow. Once it's tested then we can move on to the next test."
          },
          {
            ConversationId: "1",
            Message: "Test Message 7. we should be able to see this message as well. And this should be long enough to wrap around the screen and test the overflow. Once it's tested then we can move on to the next test."
          }
        ] as Message[]);
        console.log(err);
      });

  }, [cookies.token, cookies.userId]);



  useEffect(() => {
    fetchMessages();
    return () => {
      // Remove event listeners.
    };
  }, [fetchMessages]); // Empty array ensures that effect is only run on mount and unmount

  const handleNewMessage = () => {
    setIsLoading(true);
    //console.log(cookies.token);
    if (cookies.userId === undefined) {
      //console.log("User unauthorized");
      return;
    }
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + cookies.token;
    axios.post("https://api.ritadhi.com", {
      UserId: cookies.userId,
      SessionId: "1",
      Message: newMessage,
    }).then((res) => {
      if (res.status === 201) {
        fetchMessages();
        setNewMessage("");
      } else {
        setNewMessage(newMessage);
      }
    })
      .catch((err) => {
        setNewMessage(newMessage);
        console.log(err);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    event.target.style.height = "auto";
    event.target.style.height = (event.target.scrollHeight) + "px";
    setNewMessage(value);
    //setState({ ...state, [name]: value });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      //console.log(newMessage);
      //setNewMessage(newMessage);
      handleNewMessage();
    }
  };

  return (
    <div className="App">
      <header className="App-header">

        {cookies.loginStatus === '1' ? <p>{cookies.email} is Logged in</p> : <p>Log in for using personal assistant</p>}
      </header>
      {cookies.loginStatus === '1' ?
      <div className='chat-div'>
        <div className="Chat-Body"><ul>
          {messages.map((message) => (
            <li key={message.ConversationId}>
              {message.Message}
            </li>
          ))}
        </ul>
        </div>
        <div className="Chat-Input-Container">
        <textarea id="chat-input" value={newMessage} onChange={handleInputChange} onKeyDown={handleKeyDown} />
        <button id="send-button" onClick={handleNewMessage}>Send</button>
      </div>
      </div>
        : null}
      {isLoading && <div className="overlay">Loading...</div>}
    </div>
  );
}

export default Home;