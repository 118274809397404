
import { HashRouter, Route, Routes } from 'react-router-dom';
import About from "./About/About";
import Home from "./Home/Home";
import Contact from './Contact/Contact';
import NavBar from './NavBar/NavBar';
import Login from './Login/Login';
import Signup from './Login/Signup';

function App() {
  return (
    <div className="App">
      <HashRouter>  
        <NavBar />    
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/login' element={<Login/>} />
          <Route path='/signup' element={<Signup/>} />
        </Routes>
      </HashRouter>
  </div>
  );
}

export default App;
