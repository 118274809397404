import { Link } from 'react-router-dom';
import logo from '../logo.svg';
import '../App.css';
import { useCookies } from 'react-cookie';

const NavBar = () => {
  const [cookies, removeCookie] = useCookies(['loginStatus', 'email']);

  function handleLogout() {
    removeCookie('email', { path: '/' });
    removeCookie('loginStatus', { path: '/' });
  }
    return (
      <div className="Navbar-Container">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="NavBar-Links">
        <Link className="NavBar-Link" to="/">Home</Link>
        {cookies.loginStatus !== "1" && (
        <Link className="NavBar-Link" to="/login">Login</Link>
        )}
        {cookies.loginStatus === "1" && (
          <Link className="NavBar-Link" to="/" onClick={handleLogout}>Logout</Link>
        )}
        <Link className="NavBar-Link" to="/about">About</Link>
        <Link className="NavBar-Link" to="/contact">Contact</Link>
        </div>
      </div>
    );
  };

export default NavBar;