import '../App.css';

function Contact() {
    return (
    <div className="App">
      
        <p>
          This is the contact page !
        </p>
    </div>
    );
}

export default Contact;